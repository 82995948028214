import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '@environments';
import { map, catchError } from 'rxjs/operators';
import { UserResponse } from '@models/user/user.model';
import { AppErrorHandler } from '@base/error-handler';
import { HeadersService } from './headers.service';

@Injectable({
  providedIn: 'root',
})
export class DataUserService {
  //public
  public currentUser: Observable<any>;
  //private
  private currentUserSubject: BehaviorSubject<any>;
  private authToken = environment.apiUrl + '/auth/verify';
  private signout = environment.apiUrl + '/auth/logout';
  private currentProfile$: BehaviorSubject<string>;

  constructor(
    private _http: HttpClient,
    private errorService: AppErrorHandler,
    private headers: HeadersService,
  ) {
    const sessionUser = sessionStorage.getItem('currentUser');    
    if(sessionUser == 'undefined') {
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('tokenAzure');
      location.reload();
      return;
    }
    this.currentUserSubject = new BehaviorSubject<UserResponse>(
      JSON.parse(sessionUser)
    );

    let filter = [];
    filter = this.currentUserSubject.value?.profile.filter(e => e == 'pentester')
    this.currentProfile$ = new BehaviorSubject<string>(
    filter != null &&  filter.length > 0 ? filter[0] : this.currentUserSubject.value?.profile[0] || '');
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public validatorAuth(): Observable<any> {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('tokenAzure')}`,
    };
    return this._http.get(this.authToken, { headers: headers }).pipe(
      map((response) => response),
      catchError(this.errorService.errorHandler)
    );
  }

  public updateCurrentProfile(profile: string): void {    
    this.currentProfile$.next(profile);
  }

  public getCurrentProfile$(): Observable<string> {
    return this.currentProfile$.asObservable();
  }

  public logout(){
    this.currentUserSubject.next(null);
    return this._http
    .get(`${this.signout}`, this.headers.getHeader())
    .pipe(
      map(response => response),
      catchError(this.errorService.errorHandler)
    );
  }

}
