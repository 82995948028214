export const quillToolbarConfig = {
    toolbar: [
      ['bold'],
      ['code-block'],
      [{ 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      ['clean']                                         // remove formatting button
    ]
  };
  