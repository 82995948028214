import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver, ScreenTypes } from '@angular/flex-layout';

import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreMediaService {

  public isHandset$: Observable<boolean>;
  currentMediaQuery: string;
  onMediaUpdate: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * Constructor
   *
   * @param {MediaObserver} _mediaObserver
   */
  constructor(private _mediaObserver: MediaObserver) {
    // Set the defaults
    this.currentMediaQuery = '';

    // Initialize
    this._init();
  }

  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
      this.isHandset$ = this._mediaObserver
        .asObservable()
        .pipe(
          map((changes: MediaChange[]) =>
            changes.some(
              (change: MediaChange) => {
                if (this.currentMediaQuery !== change.mqAlias) {
                  this.currentMediaQuery = change.mqAlias;
                  this.onMediaUpdate.next(change.mqAlias);
                }
              }
            )
          )
        );
  }

}
