import { CoreMenu } from '@core/types'

  export const menuAdmin: CoreMenu [] = [{
      id: 'dashboard',
      title: 'Tablero',
      type: 'collapsible',
      icon: 'home',
      children: [
        {
          id: 'summary',
          title: 'Resumen',
          type: 'item',
          // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
          icon: 'minus',
          url: 'pages/tablero'
        },
        {
          // If role is not assigned will be display to all
          id: 'projects',
          title: 'Proyectos',
          type: 'item',
          icon: 'minus',
          url: 'pages/proyectos'
        }
      ]
    },
    {
      id: 'vulnerabilities',
      title: 'Vulnerabilidades',
      type: 'collapsible',
      icon: 'alert-octagon',
      children: [
        {
          id: 'allVulnerabilitys',
          title: 'General',
          type: 'item',
          // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
          icon: 'minus',
          url: 'pages/vulnerabilidades'
        },
        {
          // If role is not assigned will be display to all
          id: 'myVulnerabilitys',
          title: 'Mis vulnerabilidades',
          type: 'item',
          icon: 'minus',
          url: 'pages/mis-vulnerabilidades'
        },
        {
          // If role is not assigned will be display to all
          id: 'vulenrabilitysPending',
          title: 'Pendientes',
          type: 'item',
          icon: 'minus',
          url: 'pages/vulnerability-pending'
        }
      ]
    },
    {
      id: 'planning',
      title: 'Planeación',
      type: 'collapsible',
      icon: 'trello',
      children: [
        {
          id: 'pentestPeriod',
          title: 'Periodos de pentest',
          type: 'item',
          icon: 'minus',
          url: 'pages/pentests-periods'
        },
      ]
    },
    {
      id: 'reports',
      title: 'Reportes',
      type: 'collapsible',
      icon: 'list',
      children: [
        {
          id: 'reports',
          title: 'General',
          type: 'item',
          icon: 'minus',
          url: 'pages/reportes'
        },
      ]
    },
    {
      id: 'tags',
      title: 'Tags',
      type: 'item',
      icon: 'tag',
      url: 'pages/tags'
    },
    {
      id: 'Evc',
      title: 'EVC',
      type: 'item',
      icon: 'aperture',
      url: 'pages/evc'
    },
    {
      id: 'token',
      title: 'Generar API',
      type: 'item',
      icon: 'code',
      url: 'pages/api'
    },
    {id: 'wiki',
    title: 'Documentación',
    type: 'item',
    icon: 'file-text',
    url: 'pages/wiki'
    },
  ];

  export const menuPM: CoreMenu [] = [
    {
    id: 'dashboard',
    title: 'Tablero',
    type: 'collapsible',
    icon: 'home',
    children: [
      {
        id: 'summary',
        title: 'Resumen',
        type: 'item',
        // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
        icon: 'minus',
        url: 'pages/tablero'
      },
      {
        // If role is not assigned will be display to all
        id: 'projects',
        title: 'Proyectos',
        type: 'item',
        icon: 'minus',
        url: 'pages/proyectos'
      }
    ]
    },
    {
      id: 'vulnerabilities',
      title: 'Vulnerabilidades',
      type: 'collapsible',
      icon: 'alert-octagon',
      children: [
        {
          id: 'allVulnerabilitys',
          title: 'General',
          type: 'item',
          // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
          icon: 'minus',
          url: 'pages/vulnerabilidades'
        },
        {
          // If role is not assigned will be display to all
          id: 'myVulnerabilitys',
          title: 'Mis vulnerabilidades',
          type: 'item',
          icon: 'minus',
          url: 'pages/mis-vulnerabilidades'
        }
      ]
    },
    {
      id: 'tasks',
      title: 'Tareas asignadas',
      type: 'item',
      icon: 'inbox',
      url: 'pages/tasks'
    },
    {
      id: 'planning',
      title: 'Planeación',
      type: 'collapsible',
      icon: 'trello',
      children: [
        {
          id: 'pentestPeriod',
          title: 'Periodos de pentest',
          type: 'item',
          icon: 'minus',
          url: 'pages/pentests-periods'
        },
      ]
    },
    {
      id: 'reports',
      title: 'Reportes',
      type: 'collapsible',
      icon: 'list',
      children: [
        {
          id: 'reports',
          title: 'General',
          type: 'item',
          icon: 'minus',
          url: 'pages/reportes'
        },
        {
          id: 'projectExecutionReport',
          title: 'Ejecución proyecto',
          type: 'item',
          icon: 'minus',
          url: 'pages/project-execution-report'
        },
      ]
    },
    {
      id: 'token',
      title: 'Generar API',
      type: 'item',
      icon: 'code',
      url: 'pages/api'
    },
    {id: 'wiki',
    title: 'Documentación',
    type: 'item',
    icon: 'file-text',
    url: 'pages/wiki'
    },
  ];

  export const menuPentester: CoreMenu [] = [
  {
  id: 'dashboard',
  title: 'Tablero',
  type: 'collapsible',
  icon: 'home',
  children: [
    {
      id: 'summary',
      title: 'Resumen',
      type: 'item',
      // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
      icon: 'minus',
      url: 'pages/tablero'
    },
    {
      // If role is not assigned will be display to all
      id: 'projects',
      title: 'Proyectos',
      type: 'item',
      icon: 'minus',
      url: 'pages/proyectos'
    }
  ]
  },
  {
    id: 'vulnerabilities',
    title: 'Vulnerabilidades',
    type: 'collapsible',
    icon: 'alert-octagon',
    children: [
      {
        id: 'allVulnerabilitys',
        title: 'General',
        type: 'item',
        // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
        icon: 'minus',
        url: 'pages/vulnerabilidades'
      },
      {
        // If role is not assigned will be display to all
        id: 'myVulnerabilitys',
        title: 'Mis vulnerabilidades',
        type: 'item',
        icon: 'minus',
        url: 'pages/mis-vulnerabilidades'
      }
    ]
  },
  {
    id: 'tasks',
    title: 'Tareas asignadas',
    type: 'item',
    icon: 'inbox',
    url: 'pages/tasks'
  },
  {
    id: 'reports',
    title: 'Reportes',
    type: 'collapsible',
    icon: 'list',
    children: [
      {
        id: 'reports',
        title: 'General',
        type: 'item',
        icon: 'minus',
        url: 'pages/reportes'
      },
    ]
  },
  {
    id: 'token',
    title: 'Generar API',
    type: 'item',
    icon: 'code',
    url: 'pages/api'
  },
  {id: 'wiki',
  title: 'Documentación',
  type: 'item',
  icon: 'file-text',
  url: 'pages/wiki'
  },
];

export const menuGeneral: CoreMenu [] = [{
  id: 'dashboard',
  title: 'Tablero',
  type: 'collapsible',
  icon: 'home',
  children: [
    {
      id: 'summary',
      title: 'Resumen',
      type: 'item',
      // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
      icon: 'minus',
      url: 'pages/tablero'
    },
    {
      // If role is not assigned will be display to all
      id: 'projects',
      title: 'Proyectos',
      type: 'item',
      icon: 'minus',
      url: 'pages/proyectos'
    }
  ]
},
  {
    id: 'vulnerabilities',
    title: 'Vulnerabilidades',
    type: 'collapsible',
    icon: 'alert-octagon',
    children: [
      {
        id: 'allVulnerabilitys',
        title: 'General',
        type: 'item',
        // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
        icon: 'minus',
        url: 'pages/vulnerabilidades'
      },
      {
        // If role is not assigned will be display to all
        id: 'myVulnerabilitys',
        title: 'Mis vulnerabilidades',
        type: 'item',
        icon: 'minus',
        url: 'pages/mis-vulnerabilidades'
      }
    ]
  },
  {
    id: 'reports',
    title: 'Reportes',
    type: 'collapsible',
    icon: 'list',
    children: [
      {
        id: 'reports',
        title: 'General',
        type: 'item',
        icon: 'minus',
        url: 'pages/reportes'
      },
    ]
  },
  {
    id: 'token',
    title: 'Generar API',
    type: 'item',
    icon: 'code',
    url: 'pages/api'
  },
  {id: 'wiki',
  title: 'Documentación',
  type: 'item',
  icon: 'file-text',
  url: 'pages/wiki'
  },
];

export const menuCiber: CoreMenu [] = [{
id: 'dashboard',
title: 'Tablero',
type: 'collapsible',
icon: 'home',
children: [
  {
    id: 'summary',
    title: 'Resumen',
    type: 'item',
    // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
    icon: 'minus',
    url: 'pages/tablero'
  },
  {
    // If role is not assigned will be display to all
    id: 'projects',
    title: 'Proyectos',
    type: 'item',
    icon: 'minus',
    url: 'pages/proyectos'
  }
]
},
{
  id: 'vulnerabilities',
  title: 'Vulnerabilidades',
  type: 'collapsible',
  icon: 'alert-octagon',
  children: [
    {
      id: 'allVulnerabilitys',
      title: 'General',
      type: 'item',
      // role: ['Admin'], // To set multiple role: ['Admin', 'Client']
      icon: 'minus',
      url: 'pages/vulnerabilidades'
    },
    {
      // If role is not assigned will be display to all
      id: 'myVulnerabilitys',
      title: 'Mis vulnerabilidades',
      type: 'item',
      icon: 'minus',
      url: 'pages/mis-vulnerabilidades'
    },
    {
      // If role is not assigned will be display to all
      id: 'vulenrabilitysPending',
      title: 'Pendientes',
      type: 'item',
      icon: 'minus',
      url: 'pages/vulnerability-pending'
    }
  ]
},
{
  id: 'reports',
  title: 'Reportes',
  type: 'collapsible',
  icon: 'list',
  children: [
    {
      id: 'reports',
      title: 'General',
      type: 'item',
      icon: 'minus',
      url: 'pages/reportes'
    },
  ]
},
{
  id: 'tags',
  title: 'Tags',
  type: 'item',
  icon: 'tag',
  url: 'pages/tags'
},
{
  id: 'Evc',
  title: 'EVC',
  type: 'item',
  icon: 'aperture',
  url: 'pages/evc'
},
{
  id: 'token',
  title: 'Generar API',
  type: 'item',
  icon: 'code',
  url: 'pages/api'
},
{id: 'wiki',
title: 'Documentación',
type: 'item',
icon: 'file-text',
url: 'pages/wiki'
},
];