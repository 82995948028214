<!-- Footer -->
<div class="clearfix mb-0">
  <div class="d-flex justify-content-center my-1">
    <span class="mr-1 text-black">Powered by</span>
    <a class="mr-1" href="https://www.7waysecurity.co">
      <img src="{{ footerLogo }}" alt="brand" height="25"/>
    </a>
    <span class="text-black" target="_blank"><b>v{{version}}</b></span>
  </div>
</div>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
