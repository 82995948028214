import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { environment } from '@environments';
import { AppErrorHandler } from '@base/error-handler';
import { HeadersService } from './headers.service';


@Injectable({ providedIn: 'root' })
export class UploadFileService {

  uploadFile = environment.apiUrl + '/file/upload/project';
  get = environment.apiUrl + '/logo/get-file';

  constructor(
    private _http: HttpClient,
    private errorService: AppErrorHandler,
    private headers: HeadersService,
  ) { }


  public getLogo(body):Observable<any>{
    return this._http.post(this.get, body, {...this.headers.getHeader(), responseType: 'blob' as 'json'})
    .pipe(
      map((response) => response)
    );
  }

  uploadFileProject(body): Observable<any> {
    return this._http
    .post(this.uploadFile, body,  this.headers.getHeader() )
    .pipe(
      map(() => { return true; }),
      catchError(this.errorService.errorHandler)
    )
  }
}
