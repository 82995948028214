import {
  Component,
  OnInit,
  HostBinding,
  HostListener,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from 'auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { Router } from '@angular/router';
import { UserResponse } from '@models/user/user.model';
import { DataUserService } from '@services/data-user.service';
import { Subscription } from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { UploadFileService } from '@services/upload-file.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy{
  public logoDefault:string = 'assets/images/7wayops/wayops-gray.svg';
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: UserResponse;
  public currentProfile: string;
  public profileSubscription$: Subscription;
  public img: any;
  public navigation: any;
  public news: string [];

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private uploadFileService: UploadFileService,
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private _dataUserService: DataUserService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {  
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.profielSubscription();

    // Subscribe to the config changes
    this._coreConfigService.config.subscribe((config) => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });
    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    //set up news
    this.news = [
      'Se crea el rol de analista de ciberseguridad (Ciber) el cual podrá ser solicitado desde la opción solicitar rol.',
      'Se agregan filtros de búsqueda en la sección de gitroot y archivos.',
      'Se realizan ajustes en el idioma de las fechas, estilo de elementos, orden de campos en la sección de creación de fase y mensajes de alerta en la sección API.',
    ]
    window.onload = this.getLogoNav()  
  }

  ngOnDestroy(): void {
    this.profileSubscription$?.unsubscribe();
  }

  private profielSubscription(): void {    
    this.profileSubscription$ = this._dataUserService
      .getCurrentProfile$()
      .subscribe((profile: string) => {        
        this.currentProfile = profile
      });
  }

  // Public Methods

  public toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  public toggleDarkSkin(): void {
    // Get the current skin
    this._coreConfigService.getConfig().subscribe((config) => {
      this.currentSkin = config.layout.skin;
    });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      )
    } else {
      localStorage.setItem('prevSkin', this.currentSkin)
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true })
    }
  }

  getLogoNav():any {
    this._coreConfigService.getConfig().subscribe((config) => {
      this.currentSkin = config.layout.skin;
      let body = {
      type: ''
    }

    if (this.currentSkin === 'dark') {
      body.type = 'DARK_MODE'
    } else {
      body.type = 'LIGHT_MODE'
    }

    this.uploadFileService.getLogo(body).subscribe((res)=>{
      if(res.type != 'application/json'){
        let t = this
        var reader = new FileReader();
        reader.readAsDataURL(res); 
        reader.onloadend = function() {
        t.img = reader.result;  
        }
      }else{
        this.img = this.logoDefault;
      }
    })
    });
  }

  public logout(): void {
    this._dataUserService.logout().subscribe((res)=> {
      if(res.ok){
        localStorage.clear();
        sessionStorage.clear();
        location.href ='/pages/authentication/login';
      }
    });
  }

  public setProfile(rol: string): void {
    this._dataUserService.updateCurrentProfile(rol);
  }

  public getProfileType(profile: string): string {
    switch (profile) {
      case 'manager':
        return 'Manager';
      case 'user':
        return 'Usuario';
      case 'executive':
        return 'Ejecutivo';
      case 'pentester':
        return 'Pentester';
      case 'ciber':
        return 'Ciber';
      case 'project_manager':
        return 'Project Manager';
      default:
        return profile;
    }
  }

  // modal Open Vertically Centered
  modalOpenVC(modalVC) {
    this.modalService.open(modalVC, {
      centered: true,
      size: 'lg'
    });
  }
}
