import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreMenuModule } from '@core/components';
import { CoreCommonModule } from '@core/common.module';

import { VerticalMenuComponent } from '@components/common/menu/vertical-menu/vertical-menu.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [VerticalMenuComponent],
    imports: [CoreMenuModule, CoreCommonModule, RouterModule, NgbTooltipModule],

  exports: [VerticalMenuComponent]
})
export class VerticalMenuModule {}
