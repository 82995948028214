<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->
    <figure class="navbar-logo">
      <img src="{{img}}" alt="bancolombia-logo">
    </figure>
  </div>

  <div class="bookmark-wrapper d-flex align-items-center">
    <button type="button" class="btn btn-icon btn-outline-primary" rippleEffect
            placement="bottom"
            ngbTooltip="Novedades"
            (click)="modalOpenVC(modalVC)">
      <span [data-feather]="'layers'"></span></button>
  </div>


  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{ currentUser?.first_name || 'Usuario'}}</span><span
            class="user-status">{{ getProfileType(currentProfile || 'Ninguno') }}</span>
        </div>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right w-15rem">

        <li ngbDropdown class="nav-item dropdown-user hoverDropdownRol">
          <a class="p-0 nav-link dropdown-toggle dropdown-user-link " id="dropdown-user" ngbDropdownToggle
            id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
            <div  class = "zIndexPosition w-80">
              <a ngbDropdownItem><span [data-feather]="'user'" [class]="'mr-50'"></span>Roles</a>
            </div><span class="mx-auto" [data-feather]="'chevron-down'" [class]="'mr-50'"></span>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right w-15rem mt-0">
            <div *ngFor="let rol of currentUser?.profile || []" ngbDropdownItem (click)="setProfile(rol)">{{ getProfileType(rol) || 'Ninguno' }}</div>
          </div>
        </li>
        <div class="dropdown-divider"></div>
        <li ngbDropdown class="nav-item dropdown-user hoverDropdownRol">
          <a class="p-0 nav-link dropdown-toggle dropdown-user-link " id="dropdown-user" ngbDropdownToggle
            id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
            <div  class = "zIndexPosition w-80" >
              <a ngbDropdownItem><span [data-feather]="'settings'" [class]="'mr-50'"></span>Configuración</a>
            </div><span class="mx-auto" [data-feather]="'chevron-down'" [class]="'mr-50'"></span>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right w-15rem mt-0">
            <div [routerLink]="'pages/notifications'" ngbDropdownItem ><span [data-feather]="'bell'" [class]="'mr-50'"></span>Notificaciones</div>
          </div>
        </li>
        <div class="dropdown-divider"></div>
        <li ngbDropdown class="nav-item hoverDropdownRol">
          <a class = "bg-none" (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Cerrar sesión</a>
        </li>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
  <figure class="navbar-powered-logo d-none d-sm-block">
    <img src="assets/images/7wayops/wayops-gray.svg" alt="powered-by-7wayops">
  </figure>
</div>

<!-- Modal -->
<ng-template #modalVC let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">Novedades</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div tabindex="0" class="row" ngbAutofocus>
    <div class="col">
      <img  class="imgNews" src="../assets/images/7wayops/novedades.jpeg" alt="Novedades">
    </div>
    <div class="col text-center margin">
      <img class="navbar-powered-logo" src="../assets/images/7wayops/wayops-logo.png" alt="Novedades">
      <h2>Actualizaciones</h2>
      <hr style="width: 70%">
      <span *ngFor="let new of news">
        <p><span [data-feather]="'disc'"></span> {{new}}</p>
      </span>
    </div>
  </div>
  <div class="modal-footer">
    <button routerLink="/pages/wiki" (click)="modal.dismiss('Cross click')" class="btn btn-primary" rippleEffect
      >
      Ver más
    </button>
  </div>
</ng-template>
<!-- / Modal -->
